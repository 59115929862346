<template>
  <page-content :show-search="true">
    <template #search>
      <table-search
        ref="search"
        :fields="search_fields"
        :default="search_default"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <edit-field-header
        :title="title"
        @routerBack="$router.push({name: 'kioskMemberFaceId'})"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      />
    </template>

  </page-content>
</template>

<script>
import common from '@/common'
import TableContent from '@/components/TableContent.vue'
// import TableHeader from '@/components/TableHeader'
import TableSearch from '@/components/TableSearch.vue'
import PageContent from '@/components/PageContent.vue'
import EditFieldHeader from "@/components/EditFieldHeader.vue";

export default {
  components: {
    PageContent,
    TableSearch,
    // TableHeader,
    TableContent,
    EditFieldHeader,
  },
  name: 'kioskMemberCollectionId',
  data() {
    return {
      title: this.$t('kiosk.collection_id_management'),
      view_permission: common.checkPermission('Kiosk_CollectionID_List'),
      // view_permission: true,
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage(),
      },
      status_map: [],
      header_actions: [],
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: '公司',
          field: 'companyName',
          width: '240px',
        },
        {
          label: '院舍',
          field: 'facilityName',
          width: '240px',
        },
        {
          label: '院舍編號',
          field: 'facilityCode',
          width: '120px',
        },
        {
          label: 'CollectionID',
          field: 'collectionID',
          changeHTML: (val, _row) => val ? `<span class="badge badge-light-secondary wh-font-family-monospace mr-05 font-weight-normal">${val}</span>` : '',
        },
        {
          label: 'common.action',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      table_actions: [
        {
          text: 'common.edit',
          variant: 'primary',
          fun: 'editData',
          // permission: 'Kiosk_CollectionID_Edit'
        },
      ],
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          show: common.isAdmin(),
          clearable: true,
        },
        // {
        //   field: 'facilityID',
        //   label: 'common.facility',
        //   type: 'select',
        //   options: [],
        //   show: common.isAdmin(),
        //   clearable: true,
        // },
      ],
      search_default: {
        companyID: null,
        // facilityID: null,
      },
    }
  },
  mounted() {
    if (!common.isAdmin()) {
      // common.getSearchFloorOptions('floorID', common.getRole().facilityID, this)
    } else {
      common.getSearchCompanyOptions('companyID', this)
      // common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
      // common.getSearchFloorOptions('floorID', common.getAdminFacilityId(), this)
    }
    this.getList()
  },

  methods: {
    getList: async function () {
      if (!this.view_permission) return

      const api = '/memberface/GetFaceCollectionList'
      const search = this.getSearchCondition()
      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)
      const { data } = await common.apiGetData(url)

      // Assign value to current component
      this.total_rows = data.total
      this.rows = data.facilityFaces
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    editData: function (data) {
      this.$router.push({
        name: 'kioskMemberFaceId_ManageCollectionId_edit',
        params: { id: common.encrypt(data.facilityID) },
      })
    },

    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      // const vmOfSearch = this.$refs.search
      // common.syncSearchFields({
      //   companyID: vmOfSearch.list.companyID,
      //   facilityID: vmOfSearch.list.facilityID,
      //   floorID: vmOfSearch.list.floorID,
      //   roomID: vmOfSearch.list.roomID,
      // })
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false,
      })
    },

    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      // where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    changeStatus: function (value) {
      for (const i in this.status_map) {
        if (this.status_map[i].value === value) {
          return this.status_map[i].text
        }
      }
    },
  },
}
</script>

<style lang="scss">

</style>
